@font-face {
  font-family: 'Canela';
  src:  url('../fonts/Canela-Bold.woff2') format('woff2'),
        url('../fonts/Canela-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Monosten';
  src:  url('../fonts/Monosten-E.woff2') format('woff2'),
        url('../fonts/Monosten-E.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
